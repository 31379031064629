import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Privacy from './privacy'
import Terms from './terms'
import ComingSoon from './comingsoon'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Route,Routes } from 'react-router-dom';
const root = ReactDOM.createRoot(
  document.getElementById('root') 
);
root.render(
  <React.StrictMode>
  <BrowserRouter>
  <Routes> 
  <Route path="/" element={<App />} />
  <Route path="/privacy" element={<Privacy />} />
  <Route path="/terms" element={<Terms />} />
  <Route path="/commingsoon" element={<ComingSoon/>} />
  
  </Routes>
    </BrowserRouter>
</React.StrictMode> 
);


// reportWebVitals();
